import { Paper, Typography, Box } from "@material-ui/core";
import React, { useState } from "react";
import { Affix, Avatar, Image, Spin } from "antd";
import { makeStyles } from "@material-ui/core";
import { primaryColor } from "../../../../Theme/master";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import ButtonUpload from "./ButtonUpload";
import ButtonDelete from "./ButtonDelete";
import {
  IconCustomer,
  IconDashboard,
  IconResetPassword,
  IconUser,
  SVGContract,
} from "./IconMenu";
import queryString from "query-string";

const useStyles = makeStyles(() => ({
  rootContent: {
    marginTop: 0,
  },
  avtUser: {
    padding: 40,
    textAlign: "center",
    marginBottom: 20,
    cursor: "pointer",
  },
  category: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 24,
    paddingRight: 24,
  },
  textName: {
    fontWeight: 600,
    marginTop: 10,
  },
  textNameUser: {
    fontWeight: 600,
    marginTop: 24,
  },
  groupBtn: {
    display: "none",
  },
  avatar: {
    transition: "all 0.5s",
    "&>.btn-group": {
      transition: "all 0.5s",
      display: "none",
    },
    "&:hover": {
      transition: "all 0.5s",
      "&>.btn-group": {
        transition: "all 0.5s",
        display: "block",
      },
    },
  },
  itemRowText: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    gap: 16,
    cursor: "pointer",
    "&:hover": {
      "&>p": {
        color: primaryColor,
        fontWeight: 600,
      },
      "&>rect": {
        color: `${primaryColor} !important`,
      },
    },
    marginTop: 24,
    "&:first-child": {
      marginTop: 0,
    },
  },
}));

export const ListMenuUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const search = queryString.parse(location.search);
  return (
    <Paper elevation={0} className={classes.category}>
      <Box className={classes.itemRowText}>
        <IconDashboard />
        <Typography
          onClick={() => history.push("/user/dashboard")}
          style={{
            color: location.pathname === "/user/dashboard" ? primaryColor : "",
            fontWeight: location.pathname === "/user/dashboard" ? 600 : 400,
          }}
        >
          Tổng Quan
        </Typography>
      </Box>
      <Box className={classes.itemRowText}>
        <IconCustomer />
        <Typography
          onClick={() => history.push("/user/account?type=0")}
          style={{
            color:
              location.pathname === "/user/account" &&
              (_.isEmpty(search.type) || !parseInt(search.type))
                ? primaryColor
                : "",
            fontWeight:
              location.pathname === "/user/account" &&
              (_.isEmpty(search.type) || !parseInt(search.type))
                ? 600
                : 400,
          }}
        >
          Thông tin khách hàng
        </Typography>
      </Box>
      <Box className={classes.itemRowText}>
        <SVGContract />
        <Typography
          onClick={() => history.push("/user/contracts")}
          style={{
            color: location.pathname === "/user/contracts" ? primaryColor : "",
            fontWeight: location.pathname === "/user/contracts" ? 600 : 400,
          }}
        >
          Ký hợp đồng
        </Typography>
      </Box>
      <Box className={classes.itemRowText}>
        <IconUser />
        <Typography
          onClick={() => history.push("/user/account?type=1")}
          style={{
            color:
              location.pathname === "/user/account" &&
              parseInt(search.type) === 1
                ? primaryColor
                : "",
            fontWeight:
              location.pathname === "/user/account" &&
              parseInt(search.type) === 1
                ? 600
                : 400,
          }}
        >
          Thông tin tài khoản
        </Typography>
      </Box>

      <Box className={classes.itemRowText}>
        <IconResetPassword />
        <Typography
          onClick={() => history.push("/user/account?type=2")}
          style={{
            color:
              location.pathname === "/user/account" &&
              parseInt(search.type) === 2
                ? primaryColor
                : "",
            fontWeight:
              location.pathname === "/user/account" &&
              parseInt(search.type) === 2
                ? 600
                : 400,
          }}
        >
          Đổi mật khẩu
        </Typography>
      </Box>
    </Paper>
  );
};

const SideBar = ({ matches }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  const { user, customer } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const noavatar = `https://w7.pngwing.com/pngs/754/2/png-transparent-samsung-galaxy-a8-a8-user-login-telephone-avatar-pawn-blue-angle-sphere-thumbnail.png`;
  const search = queryString.parse(location.search);

  return (
    <div className={classes.rootContent}>
      <Image
        width={200}
        style={{ display: "none" }}
        src={user.avatar}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
      <Spin
        spinning={loading}
        indicator={<PulseLoader size={8} color={primaryColor} />}
      >
        <Paper elevation={0} className={classes.avtUser}>
          <div style={{ position: "relative" }} className={classes.avatar}>
            <Avatar
              size={160}
              src={user.avatar_thumb ?? noavatar}
              onClick={() => user.avatar && setVisible(true)}
            />
            <div className="btn-group">
              <ButtonUpload loading={loading} setLoading={setLoading} />
              {user.avatar && (
                <ButtonDelete loading={loading} setLoading={setLoading} />
              )}
            </div>
          </div>
          <div className={classes.textNameUser}>
            {user.customer_name} - {user.phone}
          </div>
          <div className={classes.textName}>{customer.customer_name}</div>
        </Paper>
      </Spin>
      <div>
        <ListMenuUser />
      </div>
    </div>
  );
};

export default SideBar;
